'use strict';

$(document).on('ready', function() {
	//isotope
	(function(){
		let grid = $('.grid').isotope({
		  itemSelector: '.grid__item',
		  layoutMode: 'fitRows',
		  percentPosition: true,
		});

		let filterBtn = $('.filtering__btn');

		filterBtn.on('click', function(e){
			e.preventDefault();
			
			let $this = $(this),
					category = $this.data().category;

			grid.isotope({
				filter: category
			})
		});
	}());
	
	//init
	(function(){
		$("a[href*='#']").mPageScroll2id();
	}());

	//slider
	(function(){
		$('.background__slider').slick({
		  dots: false,
		  arrows: false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 5000,
		  speed: 1500,
		  fade: true,
		  cssEase: 'linear',
		  pauseOnHover: false
		});

		$('.slider').slick({
		  dots: false,
		  arrows: true,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 3000,
		  speed: 1500,
		  fade: true,
		  pauseOnHover: false,
		  prevArrow: $('.slider__prev'),
		  nextArrow: $('.slider__next'),
		});

		$('.feedback__list').slick({
		  dots: true,
		  dotsClass: 'feedback__dots',
		  appendDots: $('.feedback__dots-container'),
		  arrows: false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 3000,
		  speed: 1500
		});

		$('.contacts__slider').slick({
			dots: false,
		  arrows: false,
		  infinite: true,
		  autoplay: true,
		  autoplaySpeed: 3000,
		  speed: 1500
		});
	}());

	//colorbox
	(function(){
		let link = $('.review__link'),
				gallery = $('.gallery__link');

		link.colorbox({
			rel: 'reviews'
		});

		gallery.colorbox({
			rel: 'gallery'
		})
	}());

	//subscribe
	(function(){
		let openSub = $('.open__banner'),
				subConteiner = $('.subscribe__dropdown-wrap')
				close = $('.subscribe__dropdown-close');

		close.on('click', function(e){
			e.preventDefault();

			subConteiner.removeClass('active');
		});
		
		openSub.on('click', function(e){
			e.preventDefault();

			subConteiner.addClass('active');
		});
	}());

	//scroll
	(function(){
		const elem = $('.header'),
					point = 555,
					btn = $('#toTop');

		$(window).on('scroll', function(e) {
			if(elem.offset().top >= point){
				if(!btn.hasClass('active')){
					btn.addClass('active');
				}
			}else{
				if(btn.hasClass('active')){
					btn.removeClass('active');
				}
			}
		});
	}());

	//accardion
	(function(){
		let faqLink = $('.question__link'),
				flag = true;

		faqLink.on('click', function(e){
			e.preventDefault();

			let $this = $(this),
					item = $this.closest('li'),
					content = item.find('.question__content');

			if(!item.hasClass('active')){
				item.addClass('active')
				.siblings().removeClass('active');
				if(item.siblings().length !== 0){
					item.siblings().find('.question__content').slideUp(200, 'linear', function(){
						content.slideDown(200, 'linear');
					});
				}else{
					content.slideDown(200, 'linear');
				}
			}else{
				item.removeClass('active');
				content.slideUp(200, 'linear')
			}
		});
	}());

	//dropdown__mnu
	(function(){
		let mnuItem = $('.mnu__item'),
				openLink = $('.open__hided-mnu'),
				flag = true;

		openLink.on('click', function(e){
			e.preventDefault();

			let mnu = $('.mnu');

			mnu.toggleClass('active');
		})

		mnuItem.on('mouseenter', function(e){
			let $this = $(this),
					dropdownMnu = $this.find('.dropdown__mnu-list')

			if(flag){
				dropdownMnu.slideDown(300, function(){
					flag = false;
				});
				dropdownMnu.addClass('active');
			}
		});

		mnuItem.on('mouseleave', function(){
			let $this = $(this),
					dropdownMnu = $this.find('.dropdown__mnu-list')

			dropdownMnu.slideUp(300, function(){
				flag = true;
			})
			dropdownMnu.removeClass('active')
		});
	}());

	//filter dropdown
	(function(){
		let filterBtn = $('.filter__btn');

		filterBtn.on('click', function(e){
			e.preventDefault();

			let filterList = $('.filtering__list');

			if(!filterList.hasClass('active')){
				filterList.slideDown(300, function(){
					filterList.addClass('active');
				})
			}else{
				filterList.slideUp(300, function(){
					filterList.removeClass('active');
				})
			}
		});
	}());

	//map
	(function(){
		let astana = document.getElementById('astana'),
  			karaganda = document.getElementById('karaganda'),
  			almaty = document.getElementById('almaty');

  	if(astana == null && karaganda == null && almaty == null) return null;

		ymaps.ready(init);
    var myMap,
    		myPlacemark;

    function init(){
    	if(astana !== null){
    		myMap = new ymaps.Map("astana", {
	        center: [51.16929968, 71.47496181],
	        zoom: 13
	      });

	      myPlacemark = new ymaps.Placemark([51.16929968, 71.47496181], { 
	        hintContent: 'СтроймартГипер"(бывший МАСТЕР2)', 
	        balloonContent: 'Казахстан, Астана, улица Циолковского, 4' 
	      });

	      myMap.geoObjects.add(myPlacemark);
    	}
    	if(karaganda !== null){
				myMap = new ymaps.Map("karaganda", {
	        center: [49.80857456, 73.08072886],
	        zoom: 11
	      });

	      myPlacemark = new ymaps.Placemark([49.80857456, 73.08072886], { 
	        hintContent: 'ТЦ Строймарт (Юго-Восток)', 
	        balloonContent: '137 уч. квартал, стр 39 ' 
	      });

	      myMap.geoObjects.add(myPlacemark);
    	}
    	if(almaty !== null){
    		myMap = new ymaps.Map("almaty", {
	        center: [43.23723557, 76.91504850],
	        zoom: 13
	      });

	      myPlacemark = new ymaps.Placemark([43.23723557, 76.91504850], { 
	        hintContent: '2 этаж', 
	        balloonContent: 'улица Байзакова 280' 
	      });

	      myMap.geoObjects.add(myPlacemark);
    	}
    }
	}());
})